import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const PriceListSection = ({
  image,
  title,
  text,
  imageHeight,
  imageAlt,
  mobileImageHeight,
  bgColour,
  brochure,
  brochureImage,
  priceList,
  priceListImage,
  rightColumnHeading,
}) => {
  const data = useStaticQuery(graphql`
    query {
      img1: wpMediaItem(title: { eq: "brochure" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      img2: wpMediaItem(title: { eq: "price-list" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  `);

  const height = imageHeight || "";
  const mobileHeight = mobileImageHeight || "";
  return (
    <section className={`py-5 py-lg-7  position-relative ${bgColour}`}>
      <Container className="">
        <Row>
          <Col>
            <h2
              style={{ zIndex: 2 }}
              className=" position-relative mb-4 text-primary"
            >
              {title}
            </h2>
          </Col>
        </Row>
        <Row className=" g-3 g-lg-8">
          <Col lg={{ span: 7 }}>
            <Row className="align-items-center gy-4">
              <Col lg={7}>
                <GatsbyImage
                  style={{ height: height }}
                  className="position-relative w-100 d-none d-lg-block"
                  image={image}
                  alt={imageAlt}
                />
                <GatsbyImage
                  style={{ height: "30rem" }}
                  className="position-relative w-100 d-none d-md-block d-lg-none"
                  image={image}
                  alt={imageAlt}
                />
                <GatsbyImage
                  style={{ height: mobileHeight }}
                  className="position-relative w-100 d-md-none"
                  image={image}
                  alt={imageAlt}
                />
              </Col>
              <Col lg={5}>{text}</Col>
            </Row>
          </Col>
          {(brochure || priceList) && (
            <Col lg={{ span: 5 }}>
              <h3 className=" mb-4 text-primary fs-2">
                {rightColumnHeading ?? "Brochure & Price list"}
              </h3>
              <Row className="g-xl-5 g-3">
                {brochure && (
                  <Col xs={6} md={4} lg={6}>
                    <a
                      className="white-link"
                      target="_blank"
                      rel="noreferrer"
                      href={brochure.url}
                    >
                      <div
                        style={{ boxShadow: "0px 3px 40px #00000029" }}
                        className="position-relative"
                      >
                        <p
                          style={{ zIndex: 3 }}
                          className="ssp-semibold d-inline-block position-absolute top-50 start-50 translate-middle mb-0 pb-0 fs-5 "
                        >
                          {brochure?.title}
                        </p>
                        <GatsbyImage
                          style={{ zIndex: 2 }}
                          className="position-relative w-100"
                          image={
                            brochureImage.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={brochureImage.altText}
                        />
                      </div>
                    </a>
                  </Col>
                )}
                {priceList && (
                  <Col xs={6} md={4} lg={6}>
                    <a
                      className="primary-link"
                      target="_blank"
                      rel="noreferrer"
                      href={priceList.url}
                    >
                      <div
                        style={{ boxShadow: "0px 3px 40px #00000029" }}
                        className="position-relative"
                      >
                        <p
                          style={{ zIndex: 3 }}
                          className="ssp-semibold d-inline-block position-absolute top-50 start-50 translate-middle mb-0 pb-0 fs-5 "
                        >
                          {priceList.title}
                        </p>
                        <GatsbyImage
                          style={{ zIndex: 2 }}
                          className="position-relative w-100"
                          image={
                            priceListImage.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={priceListImage.altText}
                        />
                      </div>
                    </a>
                  </Col>
                )}
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default PriceListSection;
